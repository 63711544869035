import { FormInitialValues } from '../components/identificationProfessionnel/validation/formInitialValues';

interface FormField {
  name: string;
  label: string;
  requiredErrorMsg?: string;
  Placeholder?: string;
}

export interface FormModel {
  formId: string;
  formField: {
    [K in keyof FormInitialValues]: FormField;
  };
}

const formModel: FormModel = {
  formId: 'constitution-dossier',
  formField: {
    companyName: {
      name: 'companyName',
      label: 'Nom de l’entreprise',
      Placeholder: 'Top Rénovation, [code postal]',
      requiredErrorMsg: 'Nom de l’entreprise est requis',
    },
    siret: {
      name: 'siret',
      label: 'SIRET de l’entreprise',
      Placeholder: '12357849579214',
      requiredErrorMsg: "l'email est requis",
    },
    email: {
      name: 'email',
      label: 'Email de l’entreprise',
      Placeholder: 'julien@toprenovation.fr',
      requiredErrorMsg: "l'email est requis",
    },
    isSousTraitant: {
      name: 'isSousTraitant',
      label: 'Mon professionnel fait appel à un sous-traitant',
    },
    sousTraitantSiret: {
      name: 'sousTraitantSiret',
      label: 'SIRET du sous-traitant',
      Placeholder: '12357849579214',
      requiredErrorMsg: "l'email est requis",
    },
    sousTraitantCompanyName: {
      name: 'sousTraitantCompanyName',
      label: 'Nom de l’entreprise',
      Placeholder: 'Top Rénovation, [code postal]',
      requiredErrorMsg: 'Nom de l’entreprise est requis',
    },
    isCertifie: {
      name: 'isCertifie',
      label: 'En cochant cette case, je confirme que le professionnel est certifié RGE pour les travaux de {operation} au moment de la signature du devis.',
    },
    validityDate: {
      name: 'validityDate',
      label: 'Date de fin de validité du certificat de mon professionnel',
      requiredErrorMsg: 'La date de validité est requise',
    },
    devis: {
      name: 'devis',
      label: '',
      requiredErrorMsg: 'Le document de devis est requise',
    },
    dateDevis: {
      name: 'dateDevis',
      label: 'A quelle date votre devis a-t-il été signé ?',
      requiredErrorMsg: 'La date de devis est requise',
    },
    facture: {
      name: 'facture',
      label: '',
      requiredErrorMsg: 'Le document de la facture est requise',
    },
    dateFacture: {
      name: 'dateFacture',
      label: 'Date d’émission de votre facture',
      requiredErrorMsg: 'La date de facture est requise',
    },
    rib: {
      name: 'rib',
      label: '',
      requiredErrorMsg: 'Le document de la rib est requise',
    },
    ah: {
      name: 'ah',
      label: '',
      requiredErrorMsg: "Le document de l'attestation sur l’honneur est requise",
    },
    isMandataireUnique: {
      name: 'isMandataireUnique',
      label: 'En cochant cette case je confirme que cette demande de prime (CEE) a été faite auprès de Sonergia uniquement',
    },
  },
};

export default formModel;
