import React, { useEffect, useMemo, useState } from 'react';
import './identificationProfessionnel.scss';
import { FormikValues, useFormikContext } from 'formik';
import { LoaderSimple } from '@concerto-home/core';
import RechercheProfessionnelAPI from 'features/dashboard/services/professionnelAPI';
import { Professionnel } from 'features/dashboard/types/professionnel';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import { useParams } from 'react-router';
import { formatDate } from 'features/dashboard/helpers/utils';
import SearchProfessionnel from './searchProfessionnel/searchProfessionnel';
import ListProfessionnel from './listProfessionnel/listProfessionnel';
import SubSousTraitance from './Forms/subSousTraitance';
import EmailProfessionnel from './Forms/emailProfessionnel';
import MyProfessionnel from './myProfessionnel/myProfessionnel';
import SearchProOverlay from './listProfessionnel/searchProOverlay';
import { FormInitialValues } from './validation/formInitialValues';
import errorMessages from '../../lang/errorMessages.json';

const STEPS = {
  SEARCH_PRO: 1,
  SUB_SOUS_TRAITANCE: 2,
  EMAIL_PRO: 4,
  MY_PRO: 5,
};

const IdentificationProfessionnel: React.FC = () => {
  const { simulationId } = useParams();
  const { simulation, isLoading: loadingSimulation } = useSimulationResultat(simulationId || '');

  const { professionnel: editedPro } = simulation || {};
  const [professionals, setProfessionals] = useState<Professionnel[]>([]);
  const [selectedProfessional, setSelectedProfessional] = useState<Professionnel | null>(null);
  const [selectedSubSousTraitant, setSelectedSubSousTraitant] = useState<Professionnel | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(STEPS.SEARCH_PRO);
  const [showListProfessionnelModal, setShowListProfessionnelModal] = useState(false);

  const { values, errors, setFieldValue } = useFormikContext<FormInitialValues & FormikValues>();
  const { isSousTraitant, sousTraitantSiret, siret, sousTraitantCompanyName, companyName } = values;

  useEffect(() => {
    if (editedPro && editedPro.siret) {
      const { siret: editedProSiret, dateValiditeRge, email } = editedPro;
      setSelectedProfessional(editedPro);
      setFieldValue('siret', editedProSiret);
      setFieldValue('validityDate', formatDate(dateValiditeRge));
      setFieldValue('email', email);
      setCurrentStep(STEPS.MY_PRO);
    }
  }, [editedPro]);

  const handleSearchProfessional = async () => {
    try {
      setError('');
      setLoading(true);
      const response = await RechercheProfessionnelAPI.searchProfessionalBySiretOrIdentifiant({
        siret: isSousTraitant ? sousTraitantSiret : siret,
        identifiant: isSousTraitant ? sousTraitantCompanyName : companyName,
      });
      if (response.length === 0) {
        setError(errorMessages.professionalNotFound);
      }
      setProfessionals(response);
    } catch (err) {
      setError(errorMessages.professionalNotFound);
    } finally {
      setLoading(false);
    }
  };

  const handleShowListProfessionnelModal = () => setShowListProfessionnelModal(true);

  const handleCloseListProfessionnelModal = () => setShowListProfessionnelModal(false);

  const handleSelectPro = (professionnel: Professionnel) => {
    setSelectedProfessional(professionnel);
    setFieldValue('siret', professionnel.siret);
    setCurrentStep(STEPS.SUB_SOUS_TRAITANCE);
  };

  const handleSelectSubSousTraitance = (professionnel: Professionnel) => {
    setSelectedSubSousTraitant(professionnel);
    setFieldValue('sousTraitantSiret', professionnel.siret);
    setCurrentStep(STEPS.EMAIL_PRO);
  };

  const handleSubSousTraitance = (isSubSousTraitant: boolean) => {
    setFieldValue('isSousTraitant', isSubSousTraitant);

    if (isSubSousTraitant) {
      handleCloseListProfessionnelModal();
      setProfessionals([]);
      setCurrentStep(STEPS.SEARCH_PRO);
    } else {
      setCurrentStep(STEPS.EMAIL_PRO);
    }
  };

  const clearSubSousTraitance = () => {
    setSelectedSubSousTraitant(null);
    setFieldValue('sousTraitantSiret', '');
    setFieldValue('sousTraitantCompanyName', '');
  };

  const handleShowSearchProForm = () => {
    setSelectedProfessional(null);
    setFieldValue('isSousTraitant', false);
    clearSubSousTraitance();
    setCurrentStep(STEPS.SEARCH_PRO);
  };

  const handleShowSubSousTraitanceForm = () => {
    clearSubSousTraitance();
    setCurrentStep(STEPS.SEARCH_PRO);
  };

  const filterProfessionalData = useMemo(() => {
    const MAX_PROFESSIONALS = 5;
    return professionals.slice(0, MAX_PROFESSIONALS);
  }, [professionals]);

  const isValidSearchPro = () => {
    if (isSousTraitant) {
      return (sousTraitantSiret !== '' && !errors.sousTraitantSiret) || (sousTraitantCompanyName !== '' && !errors.sousTraitantCompanyName);
    }
    return (siret !== '' && !errors.siret) || (companyName !== '' && !errors.companyName);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.SEARCH_PRO:
        return (
          <SearchProfessionnel isDisabled={!isValidSearchPro()} simulation={simulation} onSearchBySiretOrIdentifiant={handleSearchProfessional} isSousTraitant={isSousTraitant} errorMessage={error}>
            <>
              <ListProfessionnel isLoading={loading} listPro={filterProfessionalData} onSelectProfessional={isSousTraitant ? handleSelectSubSousTraitance : handleSelectPro} onShowAllProfessional={handleShowListProfessionnelModal} />
              <SearchProOverlay listPro={professionals} show={showListProfessionnelModal} handleClose={handleCloseListProfessionnelModal} onSelectProfessional={isSousTraitant ? handleSelectSubSousTraitance : handleSelectPro} onSearchBySiretOrIdentifiant={handleSearchProfessional} companyName={companyName} />
            </>
          </SearchProfessionnel>
        );

      case STEPS.SUB_SOUS_TRAITANCE:
        return <SubSousTraitance onSelectSubSousTraitance={handleSubSousTraitance} />;

      case STEPS.EMAIL_PRO:
        return <EmailProfessionnel onNextStep={() => setCurrentStep(STEPS.MY_PRO)} />;

      case STEPS.MY_PRO:
        return <MyProfessionnel onShowSearchProForm={handleShowSearchProForm} onShowSubSousTraitanceForm={handleShowSubSousTraitanceForm} professional={selectedProfessional || ({} as Professionnel)} subSousTraitantPro={selectedSubSousTraitant} operation={simulation?.travaux.operation ?? ''} />;

      default:
        return null;
    }
  };

  return <>{loadingSimulation ? <LoaderSimple /> : <>{renderStepContent()}</>}</>;
};

export default IdentificationProfessionnel;
