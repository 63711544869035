import React from 'react';
import { Professionnel } from 'features/dashboard/types/professionnel';
import lang from 'features/dashboard/lang/projets.json';
import ProfessionalHeader from './professionalHeader';
import circular from '../../../assets/images/circular.png';
import ProfessionalBody from './professionalBody';
import ProfessionalFields from './professionalFields';

interface MyProfessionnelProps {
  professional: Professionnel;
  onShowSearchProForm: () => void;
  onShowSubSousTraitanceForm: () => void;
  subSousTraitantPro: Professionnel | null;
  operation?: string;
}

const MyProfessionnel: React.FC<MyProfessionnelProps> = ({ professional, onShowSearchProForm, onShowSubSousTraitanceForm, subSousTraitantPro, operation = '' }) => (
  <div className="form__container">
    <div className="form__container__title">{lang.myProfessional}</div>
    <div className="form__container__content">
      <ProfessionalHeader avatar={circular} name={professional.raisonSociale} onClick={onShowSearchProForm} label={lang.changeProfessional} />
      <ProfessionalBody professional={professional} />

      {subSousTraitantPro && (
        <>
          <div className="form__container__title">{lang.mySubcontractor}</div>
          <ProfessionalHeader name={subSousTraitantPro?.raisonSociale || ''} onClick={onShowSubSousTraitanceForm} label={lang.changeSubcontractor} />
          <ProfessionalBody professional={subSousTraitantPro} />
        </>
      )}
      <ProfessionalFields operation={operation} />
    </div>
  </div>
);

export default MyProfessionnel;
