import React from 'react';
import { Field, ErrorMessage } from 'formik';

interface TextFieldProps {
  name: string;
  label?: string;
  isNumeric?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({ name, label, isNumeric }) => {
  // if isNumeric is true, then the input field will only accept numbers onKeyPress
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (isNumeric) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  return (
    <div className="m-4">
      <label className="diagnosticQuestionTextLabel" htmlFor={name}>
        {label}
      </label>
      <Field type="text" id={name} name={name} className="form-control" onKeyPress={handleKeyPress} />
      <ErrorMessage name={name} component="div" className="text-danger text-bold mt-2" />
    </div>
  );
};

export default TextField;
