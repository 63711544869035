import React from 'react';
import './listProfessionnel.scss';
import { Professionnel } from 'features/dashboard/types/professionnel';
import lang from 'features/dashboard/lang/projets.json';
import { Spinner } from 'react-bootstrap';
import Button from '../../button/button';

import ListItem from './listItem';

interface ListProfessionnelProps {
  listPro: Professionnel[];
  onSelectProfessional: (professionnel: Professionnel) => void;
  onShowAllProfessional: () => void;
  isLoading: boolean;
}

const ListProfessionnel: React.FC<ListProfessionnelProps> = ({ listPro, onSelectProfessional, onShowAllProfessional, isLoading }) => {
  const professionalCount = listPro.length;

  if (isLoading) {
    return (
      <div className="list-professionnel--spinner">
        <Spinner animation="border" role="status" className="spinner" />
      </div>
    );
  }

  if (professionalCount === 0) {
    return null;
  }

  return (
    <div className="list-professionnel">
      <ListItem listPro={listPro} onSelectProfessional={onSelectProfessional} />
      {professionalCount > 1 && (
        <div className="list-professionnel__footer">
          <Button id="view-search-results" classNames="list-professionnel__footer__button" variant="tertiary" type="button" label={lang.viewAllSearchResults} onClick={onShowAllProfessional} />
        </div>
      )}
    </div>
  );
};

export default ListProfessionnel;
