import { api, Resource } from 'core';
import { Answer, QuestionPrime } from '../interfaces/questionnaire';
import { mapAPIOperandsQuestion } from '../mapper/operand.mapper';

interface userAnswer {
  parcoursId: string;
  userAnswers: Answer[];
}

export class OperandApi extends Resource {
  constructor(entrypoint = '/question') {
    super(entrypoint);
  }

  getOperands(userAnswers: userAnswer): Promise<QuestionPrime[]> {
    return api
      .post(this.buildUri('travaux'), {
        parcoursId: userAnswers.parcoursId,
        questions: userAnswers.userAnswers.map((userAnswer: Answer) => ({
          questionId: userAnswer.questionId,
          value: userAnswer.value,
          response: userAnswer.response,
        })),
      })
      .then(({ data }) => mapAPIOperandsQuestion(data));
  }
}

export default new OperandApi();
