import React, { useEffect, useState } from 'react';
import { Progression, resolveInputGeneric, LoaderSimple, Label, Actions, validateResponseValue } from 'core';
import ReactMarkdown from 'react-markdown';
import './questionnaire.scss';

import Exit from 'features/primes/simulation/questionnaire/components/exit/exit';
import PrimeApi from 'features/primes/simulation/questionnaire/services/primeApi';

import lang from 'features/primes/simulation/questionnaire/lang/questionnaire.json';

import { Answer, QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

import { useLocation, useNavigate } from 'react-router-dom';
import useGroups from 'features/primes/simulation/questionnaire/hooks/useGroups';
import useInitialQuestion from 'features/primes/simulation/questionnaire/hooks/useInitialQuestion';
import useResponsesIncomeFiscal from 'features/primes/simulation/questionnaire/hooks/useResponsesIncomeFiscal';
import useProgression from 'features/primes/simulation/questionnaire/hooks/useProgression';

import { getOperandesQuestions } from 'features/primes/simulation/questionnaire/hooks/getOperandesQuestions';
import { handleEnterKeyPress } from 'utils/form';
import { getEnv } from 'utils/env';
import { getUserSource, setUserSource, getUserPrescripteur, setUserPrescripteur } from 'states';
import { VALORISATION_FORMULE } from 'utils/constants';
import { addQuestions, getUserAnswersByType } from '../utils/functions';
import { useTracking } from '../hooks/useTracking';

export default function Questionnaire(): React.ReactElement {
  const navigate = useNavigate();
  const userSource = getUserSource();
  setUserPrescripteur(getUserPrescripteur());
  const location = useLocation();
  const { state } = location;

  // Source
  if (userSource !== 'concerto-primes') {
    setUserSource('concerto-primes');
  }

  // States
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState<Answer[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [codeTravaux, setCodeTravaux] = useState<string>('');
  const [responseUrl, setResponseUrl] = useState<string>('');

  // Hooks
  const { groups, setGroups, isLoading } = useGroups();
  const [questions, setQuestions] = useInitialQuestion(groups);
  useResponsesIncomeFiscal(responseUrl, userAnswers, questions, currentQuestionIndex, setQuestions);

  const currentQuestion = questions[currentQuestionIndex];
  const currentGroup = groups.find((group) => group.label === currentQuestion?.group?.label);
  const isLastQuestion = currentQuestion?.parcoursId === VALORISATION_FORMULE && currentQuestionIndex === questions.length - 1;

  const getNonOperandeUserAnswers = () => getUserAnswersByType(questions, false);
  const getOperandeUserAnswers = () => getUserAnswersByType(questions, true);

  // Tracking
  const { trackQuestionAnswer, trackEndSimulation } = useTracking(questions, currentQuestionIndex);

  // End Tracking

  const shouldExit = currentQuestion?.shouldExit || false;
  const shouldWarn = currentQuestion?.shouldWarn || false;
  const { title: sortieTitle, picto: sortiePicto, text: sortieText } = currentQuestion?.sortie || {};
  const { title: warningTitle, picto: warningPicto, text: warningText } = currentQuestion?.warning || {};

  const [showWarning, setShowWarning] = useState<boolean>(false);
  useEffect(() => {
    setShowWarning(shouldWarn);
  }, [shouldWarn]);

  const { increaseProgression, decreaseProgression } = useProgression(
    currentQuestion,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    currentGroup!,
    setGroups
  );

  const buildInitialPayload = () => {
    const payload = {
      ...currentQuestion.userAnswer,
      ...(currentQuestion.isCodeTravauxRequired && { codeTravaux }),
    };
    return payload;
  };

  const handleCodeTravaux = (response: QuestionPrime) => {
    if (response.codeTravaux) {
      setCodeTravaux(response.codeTravaux);
    }
  };

  const handleSimulation = async (idFormule?: string) => {
    const userAnswersWithoutOperande = getNonOperandeUserAnswers();
    const userAnswersOperande = getOperandeUserAnswers();

    if (idFormule) {
      localStorage.setItem('idFormule', idFormule);
    }

    localStorage.setItem('userAnswersWithoutOperande', JSON.stringify(userAnswersWithoutOperande));
    localStorage.setItem('userAnswersOperande', JSON.stringify(userAnswersOperande));

    navigate('/simulation-prime/creation', {
      state,
    });
    return <></>;
  };

  const handleLastQuestion = async (response: QuestionPrime) => {
    if (response.isLastQuestion) {
      const userAnswersWithoutOperande = getNonOperandeUserAnswers();
      const operandesQuestions = await getOperandesQuestions(currentQuestion.parcoursId, userAnswersWithoutOperande);

      if (operandesQuestions.length > 0 && operandesQuestions[0].emptyQuestion) {
        handleSimulation(operandesQuestions[0].idFormule);
      } else {
        addQuestions(questions, setQuestions, currentQuestionIndex, operandesQuestions);
      }
    }
  };

  const handleQuestionResponseUrl = (response: QuestionPrime) => {
    if (response.responseUrl && response.responseUrl !== '') {
      setResponseUrl(response.responseUrl);
    }
  };

  const postUserAnswerToGetNextQuestion = async () => {
    const initialPayload = buildInitialPayload();
    const questionResponse = await PrimeApi.postQuestion(initialPayload);

    handleCodeTravaux(questionResponse);
    handleLastQuestion(questionResponse);

    addQuestions(questions, setQuestions, currentQuestionIndex, questionResponse);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
    handleQuestionResponseUrl(questionResponse);
  };

  // Fetch next question
  const handleNextQuestion = async () => {
    if (currentQuestion && currentQuestion.shouldWarn) {
      setShowWarning(false);
      setErrors([]);
    }

    if (!questions.length) {
      return;
    }

    const errorsResponse = validateResponseValue(currentQuestion, currentQuestion?.response);

    if (errorsResponse.length > 0) {
      setErrors(errorsResponse);
      return;
    }

    if (!currentQuestion.isOperande) {
      await postUserAnswerToGetNextQuestion();
    }

    if (isLastQuestion) {
      await handleSimulation();
    }

    increaseProgression();
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  // Function to handle change
  const handleChange = (newValue: string) => {
    if (!questions.length) {
      return;
    }

    const updatedQuestions = [...questions];
    const selectedQuestion = updatedQuestions[currentQuestionIndex];

    const isChoiceType = selectedQuestion.type === 'choice';

    const selectedChoice = selectedQuestion.choices?.find((choice) => choice.value.toString() === newValue.toString());

    selectedQuestion.response = newValue;
    selectedQuestion.userAnswer = {
      questionId: selectedQuestion.questionId,
      parcoursId: selectedQuestion.parcoursId,
      value: newValue,
      response: [isChoiceType ? selectedChoice?.id?.toString() || selectedChoice?.value?.toString() || '' : newValue],
      label: selectedChoice?.label,
      isOperande: selectedQuestion.isOperande || false,
      ...(selectedQuestion.idFormule && { idFormule: selectedQuestion.idFormule }),
    };

    setQuestions(updatedQuestions);

    // Stock the user answer in the userAnswers array to be sent to the backend later
    const updatedUserAnswers = [...userAnswers];
    const existingAnswerIndex = updatedUserAnswers.findIndex((answer) => answer.questionId === selectedQuestion.questionId);

    if (existingAnswerIndex !== -1) {
      updatedUserAnswers[existingAnswerIndex] = selectedQuestion.userAnswer;
    } else {
      updatedUserAnswers.push(selectedQuestion.userAnswer);
    }

    setUserAnswers(updatedUserAnswers);

    // Target Tracking - l'utilisateur finalise la soumission du simulateur de primes (revenus)
    trackEndSimulation({ selectedQuestion });

    // Target Tracking - l'utilisateur répond à une question du simulateur de primes
    trackQuestionAnswer({ selectedQuestion });

    // If the current question is a choice type, call next question
    if (isChoiceType) {
      handleNextQuestion();
    }

    setErrors([]);
  };

  // handle back button
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex === 0) {
      return;
    }

    if (responseUrl !== '') {
      setResponseUrl('');
    }

    // remove question from questions array when going back
    const updatedQuestions = [...questions];
    if (!currentQuestion?.isOperande) {
      updatedQuestions.pop();
    }

    setQuestions(updatedQuestions);

    decreaseProgression();
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  if (isLoading) {
    return <LoaderSimple />;
  }

  // check if the next button should be disabled
  const isDisabledNext = errors.length > 0 || currentQuestion?.userAnswer?.value === '';
  const Input = !shouldExit && currentQuestion ? resolveInputGeneric(currentQuestion) : null;

  return (
    <>
      <div className={`diagnostic ${currentQuestionIndex === 0 ? 'start' : ''} primes`}>
        <Progression isInProgress={currentQuestionIndex > 0} groups={groups} currentGroup={currentGroup} isPrimesApp />
        <div className="diagnosticQuestion">
          {shouldExit && <Exit title={sortieTitle} picto={sortiePicto} text={sortieText} />}

          {showWarning ? (
            <Exit title={warningTitle} picto={warningPicto} text={warningText} />
          ) : (
            Input && (
              <>
                <Label question={currentQuestion} />
                <Input question={currentQuestion} onChange={(newValue) => handleChange(newValue)} onKeyPress={handleEnterKeyPress(handleNextQuestion)} />
              </>
            )
          )}
          <Actions
            info={
              <>
                {currentQuestionIndex === 0 && (
                  <span className="diagnosticFooterCgu">
                    <ReactMarkdown linkTarget="_blank" transformLinkUri={(uri) => `${getEnv('CMS_HOST')}${uri}`}>
                      {lang.cgu}
                    </ReactMarkdown>
                  </span>
                )}
              </>
            }
            previousLabel={lang.actions.previous}
            nextLabel={isLastQuestion ? lang.actions.calculate : lang.actions.next}
            errors={errors}
            showNext={!shouldExit}
            back={handlePreviousQuestion}
            next={handleNextQuestion}
            disabledPrevious={currentQuestionIndex === 0}
            disabledNext={currentQuestion?.shouldWarn ? false : isDisabledNext}
          />
        </div>
      </div>
    </>
  );
}
