import React from 'react';
import Button from 'features/dashboard/components/button/button';

interface ProfessionalHeaderProps {
  name: string;
  avatar?: string;
  label: string;
  onClick: () => void;
}

const ProfessionalHeader: React.FC<ProfessionalHeaderProps> = ({ name, onClick, avatar = '', label }) => (
  <div className="form__container__content__header">
    {avatar && (
      <div className="form__container__content__header--avatar">
        <img src={avatar} alt={name} />
      </div>
    )}
    <div className="form__container__content__header--name">{name}</div>
    <div className="form__container__content__header--actions">
      <Button id="change-pro" variant="tertiary" label={label} type="button" onClick={onClick} />
    </div>
  </div>
);

export default ProfessionalHeader;
