import React from 'react';
import './button.scss';
import { IconType } from 'react-icons';

interface Props {
  id: string;
  children?: React.ReactNode;
  label: string;
  type: 'button' | 'submit' | 'reset' | undefined;
  icon?: IconType | null;
  isDisabled?: boolean;
  onClick?: () => void;
  isloading?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary' | 'transparent' | 'primary-border' | 'full';
  classNames?: string;
  size?: 'small' | 'medium' | 'large';
}

const Button: React.FC<Props> = ({ id, children, label, type, icon: Icon, isDisabled, onClick, isloading, variant = 'primary', classNames, size = 'medium' }) => {
  const className = `button ${variant ? `button--${variant}` : ''} ${size ? `button--${size}` : ''} ${isDisabled ? 'disabled' : ''} ${classNames || ''}`;

  return (
    // eslint-disable-next-line react/button-has-type
    <button id={id} type={type} className={className} disabled={isDisabled} onClick={onClick} data-cy={id}>
      {isloading ? (
        <span className="button__loading" />
      ) : (
        Icon && (
          <span className="button__icon">
            <Icon size={25} />
          </span>
        )
      )}

      <span className="button__label">{label}</span>
      {children}
    </button>
  );
};

export default Button;
