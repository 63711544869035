import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'features/dashboard/components/button/button';
import { CheckboxInput, TextInput } from 'components/form/inputs';
import formModel from 'features/dashboard/models/formModel';

import lang from 'features/dashboard/lang/projets.json';
import { FormikValues, useFormikContext } from 'formik';
import { FormInitialValues } from '../validation/formInitialValues';

type ProfessionalFieldsProps = {
  operation?: string;
};

const ProfessionalFields: FC<ProfessionalFieldsProps> = ({ operation = '' }) => {
  const { formField } = formModel;
  const { isCertifie, validityDate } = formField;

  const dynamicIsCertifieLabel = isCertifie.label.replace('{operation}', operation || '');

  const { values, errors } = useFormikContext<FormInitialValues & FormikValues>();
  const { validityDate: validityDateValue } = values;
  const isValidDateRge = validityDateValue !== '' && !errors.validityDate && !errors.isCertifie;

  return (
    <div className="form__container__content__fields">
      <Row>
        <Col>
          <CheckboxInput rounded label={dynamicIsCertifieLabel} name={isCertifie.name} />
        </Col>
      </Row>
      <Row className="m-4">
        <Col>
          <TextInput tooltip={lang.tooltipInquiryProfessional} type="date" label={validityDate.label} name={validityDate.name} />
        </Col>
      </Row>

      <div className="d-flex justify-content-center mt-4  ">
        <Button id="confirm-pro" variant="primary" isDisabled={!isValidDateRge} label={lang.confirm} type="submit" classNames="m-4" />
      </div>
    </div>
  );
};

export default ProfessionalFields;
