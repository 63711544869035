import React from 'react';
import { Label, resolveInputGeneric } from '@concerto-home/core';
import { useFormikContext } from 'formik';

import TextField from './TextField';
import NumberField from './NumberField';
import SwitchField from './SwitchField';
import { getYears } from '../../helpers/functions';
import { FormValues } from '../../interfaces/generationAAT';

interface FormFieldProps {
  type: string;
  name: string;
  label?: string;
  min?: number;
  isNumeric?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({ type, name, label, min = 0, isNumeric }) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { adresseLogement, revenusFiscauxChoices, revenusFiscaux } = values;

  const handleChangeAdress = (value: string) => {
    setFieldValue('adresseLogement', value);
  };

  const handleChangeRevenus = (value: string) => {
    setFieldValue('revenusFiscaux', value);
  };

  const adress = {
    questionId: 'ADRESSE_LOGEMENT',
    label: '',
    labelInput: 'Veuillez indiquer l’adresse de votre résidence principale',
    type: 'dwelling_address',
    alias: 'dwelling_address',
    display: 'column',
    response: adresseLogement,
    rules: {
      required: true,
    },
    group: {
      id: 1,
      label: 'Informations générales',
    },
  };

  const revenus = {
    questionId: 'REVENU_FISCAUX',
    label: '',
    labelInput: '',
    type: 'choice',
    alias: 'choice',
    display: 'grid',
    response: revenusFiscaux,
    choices: revenusFiscauxChoices,
    rules: {
      required: true,
    },
    why: 'Somme des revenus fiscaux au sein du ménage :',
    help: `Utilisez les revenus les moins élevés entre ${getYears()}, les vôtres ou ceux des locataires. Si je renseigne les revenus de mes locataires, j’informe ces derniers.`,
    information: 'Cette information nous permet de vous fournir l’estimation de prime la plus précise possible. Vos données personnelles sont utilisées uniquement par Sonergia afin de calculer vos primes',
    group: {
      id: 1,
      label: 'Informations générales',
    },
  };

  const Adresss = resolveInputGeneric(adress);
  const Choice = resolveInputGeneric(revenus);

  switch (type) {
    case 'text':
      return <TextField name={name} label={label} isNumeric={isNumeric} />;
    case 'number':
      return <NumberField name={name} label={label} min={min} />;
    case 'switch':
      return <SwitchField name={name} label={label} />;
    case 'adress':
      return <Adresss question={adress} onChange={handleChangeAdress} />;
    case 'choice':
      return (
        <div className="m-4">
          <Label question={revenus} />
          <Choice question={revenus} onChange={handleChangeRevenus} />
        </div>
      );
    default:
      return <TextField name={name} />;
  }
};

export default FormField;
