import React from 'react';
import './dashboard.scss';
import { CiCirclePlus } from 'react-icons/ci';
import Button from 'features/dashboard/components/button/button';
import { useNavigate } from 'react-router';
import Lists from 'features/dashboard/components/simulations/lists/lists';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import Préconisation from 'features/dashboard/components/preconisation/preconisation';

import { useRecoilValue } from 'recoil';
import { closedSimulationsState, createdSimulationsState, inProgressSimulationsLoadingState, inProgressSimulationsState } from 'features/dashboard/states/simulations';
import Header from '../../components/header/header';
import lang from '../../lang/dashboard.json';

interface ListSection {
  title: string;
  simulationsData: Resultat[];
  isLoading: boolean;
  note?: string;
  isClosed?: boolean;
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  // Recoil states for simulations
  const inprogressSimulations = useRecoilValue(inProgressSimulationsState);
  const createdSimulations = useRecoilValue(createdSimulationsState);
  const closedSimulations = useRecoilValue(closedSimulationsState);
  const loadingInprogressSimulation = useRecoilValue(inProgressSimulationsLoadingState);
  const loadingSimulation = useRecoilValue(inProgressSimulationsLoadingState);
  const loadingClosedSimulation = useRecoilValue(inProgressSimulationsLoadingState);

  const renderListsSection = ({ title, simulationsData, isLoading, note, isClosed }: ListSection) => <Lists title={title} simulations={simulationsData} isLoading={isLoading} note={note} isClosed={isClosed} />;

  const hasSimulations = (simulationsArray: Resultat[]) => simulationsArray && simulationsArray.length > 0;

  const hasInProgressSimulations = hasSimulations(inprogressSimulations);
  const hasClosedSimulations = hasSimulations(closedSimulations);

  return (
    <div className="dashboard_profile">
      <Header />
      <div className="dashboard__actions">
        <Button id="new-project" variant="secondary" label={lang.dashboard.newProject} type="button" icon={CiCirclePlus} onClick={() => navigate('/simulation-prime')} />
      </div>
      {hasInProgressSimulations &&
        renderListsSection({
          title: 'Mes demandes en cours',
          simulationsData: inprogressSimulations,
          isLoading: loadingInprogressSimulation,
          note: createdSimulations?.length === 0 ? '' : lang.dashboard.note,
        })}
      <div className="dashboard__separator" />
      {renderListsSection({
        title: 'Mes simulations',
        simulationsData: createdSimulations,
        isLoading: loadingSimulation,
        note: inprogressSimulations?.length === 0 ? lang.dashboard.note : '',
      })}
      {/* {préconisations } */}
      <Préconisation />
      {hasClosedSimulations &&
        renderListsSection({
          title: 'Mes demandes cloturées',
          simulationsData: closedSimulations,
          isLoading: loadingClosedSimulation,
          isClosed: true,
        })}
    </div>
  );
};

export default Dashboard;
