import SimulationAPI from 'features/primes/simulation/questionnaire/services/simulationApi';
import { CHOIX_FORMULE } from 'utils/constants';
import type { Customer } from 'core';
import { clearSimulationFromLocalStorage } from 'features/dashboard/helpers/utils';
import { Answer, ISimulation, UpdateSimulation } from '../interfaces/questionnaire';

/**
 * Executes a simulation based on the user's answers.
 * @param userAnswersWithoutOperande - An array of answers without operand.
 * @param userAnswersOperande - An array of answers with operand.
 * @returns The simulation result.
 */
export const useSimulation = async (userAnswersWithoutOperande: Answer[], userAnswersOperande: Answer[], passedIdFormule?: string | null, user?: Customer | null, isEdit?: UpdateSimulation): Promise<ISimulation> => {
  const lastParcourId = userAnswersWithoutOperande[userAnswersWithoutOperande.length - 1]?.parcoursId;
  const [idFormule] = userAnswersOperande.map((answer) => answer.idFormule);
  const formattedUserAnswersWithoutOperande = userAnswersWithoutOperande.map((answer) => ({
    questionId: answer.questionId,
    value: answer.value,
    response: answer.response,
  }));

  const formattedUserAnswersOperande = userAnswersOperande.map((answer) => ({
    parcoursId: answer.parcoursId,
    questionId: answer.questionId,
    value: answer.value,
    response: answer.response,
  }));

  const choixFormule = {
    parcoursId: CHOIX_FORMULE,
    value: passedIdFormule || idFormule,
    response: passedIdFormule ? [passedIdFormule] : [idFormule],
  };

  const fostQuestions = [choixFormule, ...formattedUserAnswersOperande];

  const payload = {
    parcoursId: lastParcourId,
    questions: formattedUserAnswersWithoutOperande,
    questions_fost: fostQuestions || [],
    userId: user ? user.id : null,
  };

  let simulation;
  if (isEdit && isEdit.simulationId && isEdit.update) {
    simulation = await SimulationAPI.updateSimulation(isEdit.simulationId, payload);
  } else {
    simulation = await SimulationAPI.postSimulation(payload);
  }

  return simulation;
};

interface simulationFromLocalStorageInterface {
  userAnswersWithoutOperande: Answer[];
  userAnswersOperande: Answer[];
}

const getSimulationFromLocalStorage = (): simulationFromLocalStorageInterface | null => {
  const userAnswersWithoutOperandeJSON = localStorage.getItem('userAnswersWithoutOperande');
  const userAnswersOperandeJSON = localStorage.getItem('userAnswersOperande');

  if (userAnswersWithoutOperandeJSON === null && userAnswersOperandeJSON === null) {
    console.log('userAnswersWithoutOperande and userAnswersOperande are not set');
    return null;
  }

  if (userAnswersWithoutOperandeJSON === null) {
    console.log('userAnswersWithoutOperande is not set');
    return null;
  }

  if (userAnswersOperandeJSON === null) {
    console.log('userAnswersOperande is not set');
    return null;
  }

  const userAnswersWithoutOperandeDecode = JSON.parse(userAnswersWithoutOperandeJSON);
  const userAnswersOperandeDecode = JSON.parse(userAnswersOperandeJSON);

  if (!Array.isArray(userAnswersWithoutOperandeDecode)) {
    throw new Error('userAnswersWithoutOperandeDecode is not an array of Answer');
  }

  if (!Array.isArray(userAnswersOperandeDecode)) {
    throw new Error('userAnswersOperandeDecode is not an array of Answer');
  }

  return {
    userAnswersWithoutOperande: userAnswersWithoutOperandeDecode,
    userAnswersOperande: userAnswersOperandeDecode,
  };
};

/**
 * Executes a simulation based on the user's answers.
 * @returns The simulation result.
 * @param user
 */
export const useSimulationFromLocalStorage = async ({ user, isEdit }: { user?: Customer; isEdit?: UpdateSimulation } = {}): Promise<string | number | null> => {
  const idFormule = localStorage.getItem('idFormule');

  const simulationFromLocalStorage = getSimulationFromLocalStorage();

  if (simulationFromLocalStorage === null) {
    return null;
  }

  const { userAnswersWithoutOperande, userAnswersOperande } = simulationFromLocalStorage;

  const { simulationId } = await useSimulation(userAnswersWithoutOperande, userAnswersOperande, idFormule, user, isEdit);

  if (!simulationId) {
    throw new Error('Une erreur est survenue lors de la creation de la simulation');
  }

  clearSimulationFromLocalStorage();

  return simulationId;
};
