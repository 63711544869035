import OperandApi from 'features/primes/simulation/questionnaire/services/operandApi';
import { Answer } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

export const getOperandesQuestions = async (parcoursId: string, userAnswersWithoutOperande: Answer[]) => {
  const formattedUserAnswers = {
    parcoursId,
    userAnswers: userAnswersWithoutOperande,
  };

  const operandesQuestions = await OperandApi.getOperands(formattedUserAnswers);

  return operandesQuestions;
};
