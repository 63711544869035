import { Resultat } from '../interfaces/resultat';
import { ResultatAPI } from '../interfaces/resultatAPI';

export const mapAPIResultat = (data: ResultatAPI): Resultat => ({
  id: data.id,
  uuid: data.uuid,
  amount: data.montant,
  dateCreationSimulation: data.dateCreationSimulation,
  dateExpirationDossier: data.dateExpirationDossier,
  travaux: {
    operation: data.travaux.operation,
    dateDevis: data.travaux.dateDevis,
    picto: data.travaux.pictogramme,
    libelleComplementaire: data.travaux.libelleComplementaire,
  },
  profile: {
    status: data.profil.statut,
    numberOfPeople: data.profil.nombrePersonnes,
    revenus: data.profil.revenus,
    categorie: data.profil.categorie,
    coordonnees: {
      telephone: data.profil.coordonnees.telephone,
      email: data.profil.coordonnees.email,
    },
  },
  logement: {
    typeLogement: data.logement.typeLogement,
    address: {
      street: data.logement.adresse.rue,
      complement: data.logement.adresse.complement,
      postalCode: data.logement.adresse.code_postal,
      city: data.logement.adresse.ville,
    },
    surface: data.logement.surface,
  },
  isNonCumulable: data.isNonCumulable,
  statuts: data.statuts,
  professionnel: {
    siret: data.professionnel?.siret || '',
    raisonSociale: data.professionnel?.raisonSociale || '',
    address: {
      street: data.professionnel?.adresse?.rue || '',
      complement: data.professionnel?.adresse?.complement || '',
      postalCode: data.professionnel?.adresse?.code_postal || '',
      city: data.professionnel?.adresse?.ville || '',
    },
    dateValiditeRge: data.professionnel?.dateValiditeRge || '',
    email: data.professionnel?.email || '',
  },
  hasAat: data.hasAat,
  hasAh: data.hasAh,
  actions: {
    isDeletable: data?.actions?.estSupprimable,
    isAbandonable: data?.actions?.estAbandonnable,
    isRemplassableCarRevision: data?.actions?.estRemplacableCarRevision,
    isRemplassableCarDesactivation: data?.actions?.estRemplacableCarDesactivation,
  },
});
