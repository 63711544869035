import React, { useEffect, useRef } from 'react';
import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

export function addQuestions(questions: QuestionPrime[], setQuestions: React.Dispatch<React.SetStateAction<QuestionPrime[]>>, currentQuestionIndex: number, fetchedQuestions: QuestionPrime[] | QuestionPrime): void {
  const updatedQuestions = [...questions];
  const newQuestions = Array.isArray(fetchedQuestions) ? fetchedQuestions : [fetchedQuestions];
  updatedQuestions.splice(currentQuestionIndex + 1, updatedQuestions.length, ...newQuestions);

  setQuestions(updatedQuestions);
}

export const getUserAnswersByType = (questions: QuestionPrime[], isOperande: boolean) => questions.map((question) => ({ ...question.userAnswer })).filter((answer) => answer.value !== '' && answer.response.length > 0 && answer.isOperande === isOperande);

// TODO : remove this function when we will have a real backend id
// create a unique id by number and math.random
export function generatedID(): number {
  return Number(`${Math.random() * 1000000}${Date.now()}`);
}

export const debounce = (func: any, wait: number) => {
  let timeout: any;
  return function executedFunction(...args: any) {
    const later = () => {
      timeout = null;
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export function useEffectOnce(effect: () => void, dependency: any[]): void {
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (!hasRunRef.current) {
      effect();
      hasRunRef.current = true;
    }
  }, dependency);
}
