import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';
import { Address, Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import errorMessages from '../lang/errorMessages.json';
import lang from '../lang/projets.json';
import { DocumentData } from '../types/document';
import { QuestionsDoc } from '../types/dashboard';

interface Document {
  binary: string;
  mimeType: string;
  fileName: string;
}

// Constants
export const status = {
  isNonCumulable: 'Non cumulable',
  isClosed: 'Prime payée',
  default: 'Demander ma prime',
};

export const SIMULATION_STATUS = {
  CREATED: 'termine',
  IN_PROGRESS: 'en_cours',
  NOT_STARTED: 'non_commence',
  INCOMPLETE: 'incomplet',
  REJECTED: 'rejete',
};

export const SIMULATION = {
  CREATED: 'simulation',
  IN_PROGRESS: 'en_cours',
  CLOSED: 'cloturees',
};

export const SUPPORTED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png'];

// Helpers
export function getStatus(simulation: Resultat, isClosed?: boolean): string {
  if (isClosed) {
    return status.isClosed;
  }

  if (simulation.isNonCumulable) {
    return status.isNonCumulable;
  }

  return status.default;
}

export const getFullAdress = (adress: Address) => {
  if (!adress) {
    return '';
  }

  const { street, complement, postalCode, city } = adress;
  return `${street || ''} ${complement || ''} ${postalCode || ''} ${city || ''}`;
};

export function checkEligibility(question: QuestionPrime): { isValid: boolean; message: string } {
  const selectedChoice = question.choices?.find((choice) => choice.value.toString() === question?.response?.toString());
  const selectedChoiceLabel = selectedChoice?.label?.toLowerCase();
  const conditionMessage = question.message?.conditionMessage?.toLowerCase();
  const message = question.message?.message;

  if (selectedChoice && !selectedChoice.eligible) {
    return { isValid: false, message: question.errorLabel ?? errorMessages.ineligibleChoice };
  }
  if (conditionMessage && selectedChoiceLabel === conditionMessage) {
    return { isValid: false, message: message ?? '' };
  }

  return { isValid: true, message: '' };
}

const base64ToBlob = (base64: string, mimeType: string): Blob => {
  const byteCharacters = atob(base64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: mimeType });
};

export const downloadFile = (file: Document) => {
  const blob = base64ToBlob(file.binary, file.mimeType);
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = file.fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export function convertObjectToFile(obj: Document): File {
  const { binary, mimeType, fileName } = obj;
  const binaryString = atob(binary);
  const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));

  const blob = new Blob([bytes], { type: mimeType });
  return new File([blob], fileName, { type: mimeType });
}

export function isFieldEmptyOrNull(field: unknown) {
  if (typeof field === 'object' && field !== null) {
    return Object.values(field).some((value) => value === '' || value === null);
  }
  return field === '' || field === null || !field;
}

export function formatDate(inputDate: string): string | null {
  const [day, month, year] = inputDate.split('-');

  // Check if the input is valid
  if (Number.isNaN(parseInt(day, 10)) || Number.isNaN(parseInt(month, 10)) || Number.isNaN(parseInt(year, 10))) {
    console.error('Invalid date format');
    return null;
  }

  // Convert day and month to numbers to handle leading zeros
  const formattedDay = parseInt(day, 10).toString().padStart(2, '0');
  const formattedMonth = parseInt(month, 10).toString().padStart(2, '0');

  return `${year}-${formattedMonth}-${formattedDay}`;
}

export function clearSimulationFromLocalStorage() {
  localStorage.removeItem('userAnswersWithoutOperande');
  localStorage.removeItem('userAnswersOperande');
  localStorage.removeItem('idFormule');
}

// Function to determine the label for the button
export const labelButton = (simulation: Resultat, isDetail: boolean | undefined, isClosed: boolean | undefined, isDossierExpire: boolean | undefined) => {
  const { actions } = simulation || {};
  const { isRemplassableCarRevision, isRemplassableCarDesactivation } = actions;
  const isRemplassable = isRemplassableCarDesactivation || isRemplassableCarRevision;

  if (simulation?.isNonCumulable) {
    return status.isNonCumulable;
  }

  if (isRemplassable) {
    return lang.miseAjourPrime;
  }
  if (isDetail && isDossierExpire) {
    return lang.demandePrime;
  }
  return getStatus(simulation, isClosed);
};

export const formatDocumentsTitle = (documents: DocumentData[]) => documents.map((doc) => ({ title: doc.type.replace(/_/g, ' '), ...doc }));

// Function to filter documents
export const filterDocuments = (documents: DocumentData[]) => {
  const othersDocuments = documents?.filter((doc) => !['devis', 'facture', 'ah', 'avis_imposition'].includes(doc.type)) || [];

  const avisImpositionDocuments = documents?.filter((doc) => doc.type === 'avis_imposition') || [];

  return { othersDocuments, avisImpositionDocuments };
};

// Function to handle duplicate titles
export const handleDuplicateTitles = (documents: DocumentData[]) =>
  documents.reduce((acc: DocumentData[], current) => {
    const existingDoc = acc.find((item) => item.type === current.type);
    if (!existingDoc) {
      acc.push(current);
    } else {
      const countMatch = existingDoc.title?.match(/\((\d+)\)/);
      const count = countMatch ? parseInt(countMatch[1], 10) : 1;
      if (existingDoc.title) existingDoc.title = `${existingDoc.title.replace(/\(\d+\)$/, '')} (${count + 1})`;
    }
    return acc;
  }, []);

export function formatTitle(title: string): string {
  const isOneWord = title?.split(' ').length === 1;
  const titleFormatted = isOneWord
    ? title.toUpperCase()
    : title
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

  return titleFormatted;
}

// Helper function to get class names
export const getClassNames = (question: QuestionsDoc, index: number, currentQuestionIndex: number) => {
  const baseClass = 'file-uploader__qa__steps__indicator__step';
  const activeClass = index === currentQuestionIndex ? 'active' : '';
  const answeredClass = question.reponse === true ? 'answered' : '';
  const notAnsweredClass = question.reponse === false ? 'not-answered' : '';

  return `${baseClass} ${activeClass} ${answeredClass} ${notAnsweredClass}`;
};

export function convertDate(dateTo: string) {
  const [day, month, year] = dateTo?.split('/');

  // Create a new Date object using the components
  const dateToFormatted = new Date(`${year}-${month}-${day}`);

  return dateToFormatted;
}
