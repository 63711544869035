import * as Yup from 'yup';

// validation schema for formik using yup
export const createValidationSchema = (values: { nombreDeFoyersFiscaux: number }) => {
  const dynamicFields: { [key: string]: Yup.StringSchema<string> } = {};

  for (let i = 1; i <= values.nombreDeFoyersFiscaux; i += 1) {
    dynamicFields[`foyerFiscal${i}Nom`] = Yup.string().required(`Le nom du foyer fiscal ${i} est requis`);
    dynamicFields[`foyerFiscal${i}Prenom`] = Yup.string().required(`Le prénom du foyer fiscal ${i} est requis`);
  }

  return Yup.object().shape({
    nombreDeFoyersFiscaux: Yup.number().required('Le nombre de foyers fiscaux est requis').min(1, 'Le nombre de foyers fiscaux doit être au minimum de 1'),
    nombreDePersonnes: Yup.number().required('Le nombre de personnes est requis').min(1, 'Le nombre de personnes doit être au minimum de 1'),
    isResidencePrincipale: Yup.boolean().required('Ce champ est requis'),
    adresseLogement: Yup.string().required("L'adresse de logement est requise"),
    revenusFiscaux: Yup.string().required('Les revenus fiscaux sont requis'),
    telephone: Yup.string()
      .required('Le téléphone est requis')
      .matches(/^\d{10}$/, 'Le téléphone doit comporter exactement 10 chiffres'),
    ...dynamicFields,
  });
};
