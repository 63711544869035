import { VALORISATION_FORMULE } from 'utils/constants';
import { ModeAffichageDisplay, ModeReponseType, QuestionPrime } from '../interfaces/questionnaire';
import { Formula, Operande, QuestionApi, ReponseAPI } from '../interfaces/questionnaireAPI';
import { generatedID } from '../utils/functions';

interface MappedQuestionParams {
  key: typeof VALORISATION_FORMULE;
  question: QuestionApi;
  libelleGroupe: string;
  idFormule?: number;
}

function mapQuestion({ key, question, libelleGroupe, idFormule }: MappedQuestionParams): QuestionPrime {
  const modeReponse = ModeReponseType[question.mode_reponse] ?? 'column';
  const responseValue = question.valeur_defaut ? Number(question.valeur_defaut) : '';

  const mapChoices = (reponse: ReponseAPI) => ({
    id: reponse.uuid_reponse,
    label: reponse.libelle,
    picto: reponse.pictogramme,
    value: String(reponse.valeur),
    eligible: reponse.eligible,
  });

  return {
    ...(idFormule && { idFormule }),
    parcoursId: key,
    questionId: question?.uuid_question,
    label: question?.libelle,
    labelInput: question?.libelle_saisie_numerique ?? '',
    more: '',
    type: modeReponse,
    alias: modeReponse,
    display: ModeAffichageDisplay[question.mode_affichage] ?? 'column',
    unit: question?.unite_saisie_numerique ?? null,
    response: question?.valeur ?? responseValue,
    userAnswer: {
      questionId: question?.uuid_question,
      value: responseValue ?? '',
      response: question?.reponses?.map((reponse: ReponseAPI) => String(reponse.uuid_reponse)) ?? [],
    },
    rules: {
      required: true,
      min: question?.min ?? 1,
      max: question?.max,
    },
    why: question?.libelle_info ?? '',
    choices: question?.reponses?.map(mapChoices) ?? [],
    group: {
      id: generatedID(),
      label: libelleGroupe,
    },
    isStepProgression: question?.is_etape_progression,
    isOperande: true,
    contraintes: {
      minValue: question?.contraintes?.minValue,
      maxValue: question?.contraintes?.maxValue,
      minLength: question?.contraintes?.minLenght,
      maxLength: question?.contraintes?.maxLenght,
      oneChoiceOnly: question?.mode_reponse === 'choix_unique',
      required: question?.contraintes?.required,
      hasVariableCondition: question?.contraintes?.hasVariableCondition,
    },
    sortie: {},
  };
}

// TODO: remove this function when the backend will be fixed
// case where is no questions in the valorisation formule but the backend need the formule id
function createEmptyQuestion(formula: Formula, libelleGroupe: string): any {
  return {
    idFormule: formula.idFormule,
    parcoursId: VALORISATION_FORMULE,
    group: {
      id: generatedID(),
      label: libelleGroupe,
    },
    emptyQuestion: true,
  };
}

export function mapAPIOperandsQuestion(data: Operande): QuestionPrime[] {
  const output: QuestionPrime[] = [];

  if (data[VALORISATION_FORMULE]) {
    data[VALORISATION_FORMULE].forEach((formula: Formula) => {
      if (formula.questions.length === 0) {
        output.push(createEmptyQuestion(formula, data.libelle_groupe));
      } else {
        const mappedQuestions = formula.questions.map((question: QuestionApi) =>
          mapQuestion({
            key: VALORISATION_FORMULE,
            question,
            libelleGroupe: data.libelle_groupe,
            idFormule: formula.idFormule,
          })
        );
        output.push(...mappedQuestions);
      }
    });
  }

  return output;
}
