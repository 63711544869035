import React, { FC, useEffect, useState } from 'react';
import './informationTravaux.scss';
import { FormikValues, useFormikContext } from 'formik';
import { useParams } from 'react-router';
import useDocQuestions from 'features/dashboard/hooks/useDocQuestions';
import useAhQuestions from 'features/dashboard/hooks/useAhQuestions';
import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

import NotFound from 'pages/not-found';
import { LoaderSimple, validateResponseValue } from '@concerto-home/core';
import InformationTravauxAPI from 'features/dashboard/services/informationTravauxAPI';
import { checkEligibility, convertObjectToFile, isFieldEmptyOrNull } from 'features/dashboard/helpers/utils';
import DocumentAPI from 'features/dashboard/services/documentAPI';
import useDocuments from 'features/dashboard/hooks/useDocuments';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import useRaiVerification from 'features/dashboard/hooks/useRaiVerification';
import formModel from 'features/dashboard/models/formModel';
import AhQuestionAPI from 'features/dashboard/services/ahQuestionAPI';
import { trackClickFinishPerStep } from 'data-layer';
import { useRecoilValue } from 'recoil';
import { countInProgressSimulationsSelector } from 'features/dashboard/hooks/dashboard';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import { FormInitialValues } from '../identificationProfessionnel/validation/formInitialValues';
import QuestionModal from './FostQuestions/questionOverlay';
import IconState from './FostQuestions/iconState';
import QAFileUploaderPanel from '../QAFileUploaderPanel/QAFileUploaderPanel';
import Typography from '../typography/typography';
import Button from '../button/button';
import lang from '../../lang/projets.json';
import DevisRaiVerify from './devis/devisRaiVerify';
import ProjectNote from '../note/projectNote';

interface InformationTravauxProps {
  refresh: () => void;
}

const InformationTravaux: FC<InformationTravauxProps> = ({ refresh }) => {
  const [showQuestion, setShowQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionPrime | null>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const { simulationId } = useParams();
  const { values, setFieldValue, errors: formikErrors } = useFormikContext<FormInitialValues & FormikValues>();
  const { dateDevis, devis } = values;
  const { formField } = formModel;
  const { devis: devisField, dateDevis: dateDevisField } = formField;

  // used in tracking analytics
  const countInProgressSimulations = useRecoilValue(countInProgressSimulationsSelector);

  if (!simulationId) {
    return <NotFound />;
  }

  const { raiEnCours } = useRaiVerification(dateDevis, simulationId);
  const showRaiEnCours = raiEnCours === false && raiEnCours !== null;

  const { docQuestions } = useDocQuestions(simulationId);
  const { devis: devisQuestions } = docQuestions || {};

  const { ahQuestions, setAhQuestions, isLoading, refresh: refreshAhQustion } = useAhQuestions({ simulationId });
  const { documents } = useDocuments({ simulationId });
  const devisFile = documents && documents.find((doc) => doc.type === 'devis');
  const { simulation } = useSimulationResultat(simulationId);
  const { travaux } = simulation || {};
  const { dateDevis: retrievedDevisDate } = travaux || {};

  useEffect(() => {
    setFieldValue('devis', {
      name: devisFile?.fileName || '',
      idFile: devisFile?.idFile || '',
    });
  }, [devisFile]);

  useEffect(() => {
    setFieldValue('dateDevis', retrievedDevisDate);
  }, [retrievedDevisDate]);

  if (isLoading) {
    return <LoaderSimple />;
  }

  const showQuestionModal = (question: QuestionPrime) => {
    setErrors([]);
    setSelectedQuestion(question);
    setShowQuestion(true);
  };

  const handleClose = () => setShowQuestion(false);

  const handleChange = (newValue: string) => {
    if (!selectedQuestion) {
      return;
    }
    const updatedQuestions = [...ahQuestions];

    const isChoiceType = selectedQuestion.type === 'choice';

    const selectedChoice = selectedQuestion.choices?.find((choice) => choice.value.toString() === newValue.toString());

    selectedQuestion.response = newValue;
    selectedQuestion.userAnswer = {
      questionId: selectedQuestion.questionId,
      value: newValue,
      response: [isChoiceType ? selectedChoice?.id?.toString() || selectedChoice?.value?.toString() || '' : newValue],
    };

    setAhQuestions(updatedQuestions);

    setErrors([]);
  };

  const handleSubmit = async () => {
    if (!selectedQuestion) return;

    const errorsResponse = validateResponseValue(selectedQuestion, selectedQuestion?.response);
    const { isValid, message } = checkEligibility(selectedQuestion);
    if (errorsResponse.length > 0) {
      setErrors(selectedQuestion.errorLabel ? [selectedQuestion.errorLabel] : errorsResponse);
    } else if (!isValid) {
      setErrors([message]);
    } else {
      const res = await AhQuestionAPI.postAHQuestion(simulationId, {
        questionId: selectedQuestion.questionId,
        value: selectedQuestion.userAnswer.value,
        response: selectedQuestion.userAnswer.response,
      });

      if (selectedQuestion && selectedQuestion.choices && selectedQuestion.choices.length > 0 && selectedQuestion.choices[0].children && selectedQuestion.choices[0].children.length > 0) {
        setSelectedQuestion(selectedQuestion.choices[0].children[0]);
      } else {
        handleClose();
      }

      if (res.success) {
        refreshAhQustion();
      }
    }
  };

  const handleUploadDocument = async () => {
    let fileToUpload = devis;

    if (devis && !('path' in devis)) {
      const fetchedDocuments = await DocumentAPI.getDocuments(simulationId);
      const devisDocument = fetchedDocuments.find((doc) => doc.type === 'devis');

      if (!devisDocument) return;

      const documentData = await DocumentAPI.downloadDocument(simulationId, devisDocument.idFile);
      fileToUpload = convertObjectToFile(documentData);
    }

    if (fileToUpload) {
      const formData = new FormData();
      formData.append('fichier', fileToUpload);
      formData.append('dateDevis', dateDevis);

      const response = await InformationTravauxAPI.postInformationTravaux(simulationId, formData);

      if (response.success) {
        refresh();
      }
    }

    // Track completion of the current step
    trackClickFinishPerStep(simulation as Resultat, countInProgressSimulations, 'Informations sur vos travaux');
  };

  const allQuestionsAnswered = ahQuestions.every((question) => question.valid);
  const dateDevisError = Object.keys(formikErrors).includes(formField.dateDevis.name);

  const requiredFields = [dateDevis, devis, raiEnCours];
  const isNotValid = !allQuestionsAnswered || requiredFields.some((field) => isFieldEmptyOrNull(field)) || dateDevisError;

  return (
    <div className="information-travaux">
      <Typography variant="h2" size="xl" fontWeight="bold" customClass="mt-4 d-flex gap-3 align-items-center">
        <div className="step__number">1</div> {lang.neededInformation}
      </Typography>
      <ProjectNote note="Afin de compléter les informations ci-dessous vous devez vous munir de votre devis." />
      <div className="information-travaux__content">
        <div className="information-travaux__content__questions">
          {ahQuestions.map((ahQuestion) => (
            <div key={ahQuestion.questionId} className="information-travaux__content__questions__question" onClick={() => showQuestionModal(ahQuestion)}>
              <p className="information-travaux__content__questions__question__title">{ahQuestion.blocName}</p>
              <p className="information-travaux__content__questions__question__state">
                <IconState isAnswerd={ahQuestion.valid} />
              </p>
            </div>
          ))}
        </div>
        {selectedQuestion && <QuestionModal errors={errors} selectedQuestion={selectedQuestion} show={showQuestion} handleClose={handleClose} onSubmit={handleSubmit} onChange={handleChange} />}
        {devisQuestions && <QAFileUploaderPanel step={3} label="Votre Devis" fieldName={devisField.name} documentQuestions={devisQuestions} simulationId={simulationId} dateLabel={dateDevisField.label} dateFieldName={dateDevisField.name} />}
        {showRaiEnCours && <DevisRaiVerify />}
        <div className="information-travaux__content__actions">
          <Button id="finish-information" type="button" label={lang.finished} size="large" onClick={handleUploadDocument} isDisabled={isNotValid} />
        </div>
      </div>
    </div>
  );
};

export default InformationTravaux;
