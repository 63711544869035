export interface FormInitialValues {
  companyName: string;
  siret: string;
  email: string;
  isSousTraitant: boolean;
  sousTraitantSiret: string;
  sousTraitantCompanyName: string;
  isCertifie: boolean;
  validityDate: string;
  devis: File | null;
  dateDevis: string;
  facture: File | null;
  dateFacture: string;
  rib: File | null;
  ah: File | null;
  isMandataireUnique: boolean;
}

const initialValues: FormInitialValues = {
  companyName: '',
  siret: '',
  email: '',
  isSousTraitant: false,
  sousTraitantSiret: '',
  sousTraitantCompanyName: '',
  isCertifie: false,
  validityDate: '',
  devis: null,
  dateDevis: '',
  facture: null,
  dateFacture: '',
  rib: null,
  ah: null,
  isMandataireUnique: false,
};

export default initialValues;
