import React from 'react';
import { Professionnel } from 'features/dashboard/types/professionnel';

interface ListItemProps {
  listPro: Professionnel[];
  onSelectProfessional: (professional: Professionnel) => void;
}

const ListItem: React.FC<ListItemProps> = ({ listPro, onSelectProfessional }) => (
  <div className="list-professionnel__body">
    {listPro.map((item, i) => (
      <div className="list-professionnel__item" key={item.siret}>
        <div className="list-professionnel__item__content" onClick={() => onSelectProfessional(item)}>
          <div className="list-professionnel__item__content__key">{i + 1}</div>
          <div className="list-professionnel__item__content__name">{item.raisonSociale}</div>
          <div className="list-professionnel__item__content__fullAddress">{item.address.street}</div>
          <div className="list-professionnel__item__content__address">
            <p className="list-professionnel__item__content__address--codePostal">{item.address.postalCode}</p>
            <p className="list-professionnel__item__content__address--city">{item.address.city}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default ListItem;
