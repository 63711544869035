import React from 'react';
import { TextInput } from 'components/form/inputs';
import Typography from '../typography/typography';
import ErrorMessage from '../errorMessage/errorMessage';

interface FileDateUploaderProps {
  label: string;
  name: string;
  error?: string;
  disabled?: boolean;
}

export const FileDateUploader: React.FC<FileDateUploaderProps> = ({ label, name, error = '', disabled }) => (
  <div className="information-travaux__content__devis__uploads__date">
    <Typography variant="h3" size="xl" fontWeight="bold" customClass="m-4">
      {label}
    </Typography>
    <TextInput type="date" label="" name={name} disabled={disabled} />
    {error && <ErrorMessage message={error} />}
  </div>
);
